import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['coordinates'];

  initialize() {
    this.markers = {}; // Initialize an empty object to store markers
  }

  connect() {
    if (typeof google !== 'undefined') {
      this.renderMap();

      this.updateInterval = setInterval(() => {
        this.updateMarkers();
      }, 10000);
    } else {
      console.log("Google Maps API is not loaded.");
    }
  }

  disconnect() {
    clearInterval(this.updateInterval);
  }

  async renderMap() {
    const lat = parseFloat(this.coordinatesTarget.dataset.lat);
    const lon = parseFloat(this.coordinatesTarget.dataset.lon);

    const coordinates = { lat: lat, lng: lon };

    this.map = new google.maps.Map(this.coordinatesTarget, {
      center: coordinates,
      zoom: 10.5,
      streetViewControl: false,
      mapTypeControl: false,
    });

    // Draw district boundaries on the map
    this.drawDistrictBoundaries(this.map);

    // Define CustomOverlay class within the controller scope
    class CustomOverlay extends google.maps.OverlayView {
      constructor(position, content, map) {
        super();
        this.position = position;
        this.content = content;
        this.div = null;
        this.setMap(map); // Reference to the map instance
      }

      onAdd() {
        this.div = document.createElement('div');
        this.div.style.position = 'absolute';
        this.div.style.display = 'none'; // Initially hidden
        this.div.style.backgroundColor = 'black';
        this.div.style.borderRadius = '4px';
        this.div.style.color = 'white';
        this.div.style.padding = '10px';
        this.div.style.fontSize = '12px';
        this.div.style.fontFamily = 'Arial, sans-serif';
        this.div.style.zIndex = '100';
        this.div.innerHTML = this.content;

        const panes = this.getPanes();
        panes.floatPane.appendChild(this.div);
      }

      draw() {
        const overlayProjection = this.getProjection();
        const position = overlayProjection.fromLatLngToDivPixel(this.position);

        // Center horizontally
        const offsetX = -this.div.offsetWidth / 2;
        this.div.style.left = (position.x + offsetX) + 'px';

        // Position above the marker
        const offsetY = this.div.offsetHeight + 10; // 10px for additional spacing
        this.div.style.top = (position.y - offsetY) + 'px';
      }

      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      }
    }

    let markers_data = await this.fetch_data();
    let markers = markers_data.markers;

    markers.forEach((marker_data) => {
      const coordinates = { lat: parseFloat(marker_data.lat), lng: parseFloat(marker_data.lng) };

      let iconUrl;
      const doctorStatusResult = marker_data.doctorStatus.toLowerCase();

      if (doctorStatusResult === "offline") {
        iconUrl = "/assets/map-marker-offline.svg";
      } else {
        iconUrl = "/assets/map-marker-online.svg";
      }

      const customIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(60, 33.75),
      };

      let marker = new google.maps.Marker({
        position: coordinates,
        map: this.map,
        icon: customIcon,
      });

      // Store the marker using doctor's name as the key
      this.markers[marker_data.doctor] = marker;

      const colors = {
        "Approved": "#4285F4",
        "Cancelled": "#DB4437",
        "In Progress": "#F4B400",
      }

      const patientListItems = marker_data.patients.map(patient => {
        const statusColor = colors[patient.status] || "#000000"; // Default to black if status color is not defined
        return `<li style="display: flex; gap: 4px; align-items: center;"><span style="color: ${statusColor};">■</span> ${patient.patient_name}</li>`;
      }).join('');

      const contentString = `
      <div style="color: white;">
        <ul style="list-style: none; padding: 0; margin: 0;">
          <li><p style="font-size: 15px;">${marker_data.doctor}</p></li>
          ${patientListItems || '<li>No current booking</li>'}
        </ul>
      </div>
      `;

      // Creating and using the custom overlay
      const customOverlay = new CustomOverlay(marker.getPosition(), contentString, this.map);

      // Show the div on hover
      marker.addListener('mouseover', () => {
        customOverlay.div.style.display = 'block';
      });

      // Hide the div when not hovering
      marker.addListener('mouseout', () => {
        customOverlay.div.style.display = 'none';
      });
    });
  }

  async fetch_data() {
    const url = "/fetch_markers";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      const data = await response.json();

      if (data.error) {
        throw new Error(`Server error: ${data.error}`);
      }
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error to indicate failure
    }
  }

  drawDistrictBoundaries(map) {
    const districtCoordinates = {
      north: [
        { lat: -20.038154660405645, lng: 57.68496220893801 },
        { lat: -20.116382691420796, lng: 57.60657209403735 },
        { lat: -20.122516571262654, lng: 57.58044205573713 },
        { lat: -20.12059975965392, lng: 57.49878568604895 }
      ],
      northEast: [
        { lat: -20.122516571262654, lng: 57.58044205573713 },
        { lat: -20.153185629902676, lng: 57.545726997165175 },
        { lat: -20.21473330935444, lng: 57.532680733310045 },
        { lat: -20.222143169580967, lng: 57.60065863444995 },
        { lat: -20.208289665792627, lng: 57.789142814883306 }
      ],
      east: [
        { lat: -20.222143169580967, lng: 57.60065863444995 },
        { lat: -20.304054183759483, lng: 57.598374103536294 },
        { lat: -20.30220479564131, lng: 57.650200334640594 },
        { lat: -20.323263830267752, lng: 57.70654322812745 },
        { lat: -20.30220479564131, lng: 57.77921739508874 },
        { lat: -20.323263830267752, lng: 57.70654322812745 },
        { lat: -20.30220479564131, lng: 57.650200334640594 }
      ],
      south: [
        { lat: -20.304054183759483, lng: 57.598374103536294 },
        { lat: -20.3047825860501, lng: 57.57183812936434 },
        { lat: -20.355078765284823, lng: 57.574000927025814 },
        { lat: -20.40630811394286, lng: 57.67399679094957 },
        { lat: -20.452324205818734, lng: 57.67545174119369 },
        { lat: -20.419889982092638, lng: 57.563472353145016 },
        { lat: -20.41786307919899, lng: 57.46138830352348 },
        { lat: -20.463664565351785, lng: 57.45489991053907 },
        { lat: -20.46447508818725, lng: 57.39866717134076 },
        { lat: -20.500944185109162, lng: 57.39434157601781 },
        { lat: -20.46447508818725, lng: 57.39866717134076 },
        { lat: -20.463664565351785, lng: 57.45489991053907 }
      ],
      west: [
        { lat: -20.41786307919899, lng: 57.46138830352348 },
        { lat: -20.239498899327344, lng: 57.4555365575498 },
        { lat: -20.239881970441914, lng: 57.46451875835796 },
        { lat: -20.165298899761744, lng: 57.46927452172269 }
      ],
      portLouis: [
        { lat: -20.239881970441914, lng: 57.46451875835796 },
        { lat: -20.239346267377297, lng: 57.48815263258235 },
        { lat: -20.21473330935444, lng: 57.532680733310045 },
      ],
    };

    // Iterate through the districtCoordinates to create a Polyline for each district
    Object.keys(districtCoordinates).forEach((district) => {
      const districtPath = districtCoordinates[district];

      const districtLine = new google.maps.Polyline({
        path: districtPath,
        geodesic: true,
        strokeColor: "#FF0000",  // Red color for boundaries
        strokeOpacity: 0.8,       // Slightly transparent
        strokeWeight: 1,          // Thinner lines
        zIndex: 1,                // Ensure boundaries are below markers
      });

      districtLine.setMap(map);
    });
  }

  // New method to update markers based on latest data
  async updateMarkers() {
    try {
      let markers_data = await this.fetch_data();
      let markers = markers_data.markers;

      markers.forEach((marker_data) => {
        const doctorName = marker_data.doctor;
        const newLat = parseFloat(marker_data.lat);
        const newLng = parseFloat(marker_data.lng);

        // Check if the marker already exists
        if (this.markers[doctorName]) {
          // Update marker position
          const newPosition = { lat: newLat, lng: newLng };
          this.markers[doctorName].setPosition(newPosition);
        } else {
          // Create new marker for new doctor
          const coordinates = { lat: newLat, lng: newLng };

          let iconUrl;
          const doctorStatusResult = marker_data.doctorStatus.toLowerCase();
          if (doctorStatusResult === "offline") {
            iconUrl = "/assets/map-marker-offline.svg";
          } else {
            iconUrl = "/assets/map-marker-online.svg";
          }

          const customIcon = {
            url: iconUrl,
            scaledSize: new google.maps.Size(60, 33.75),
          };

          let marker = new google.maps.Marker({
            position: coordinates,
            map: this.map,
            icon: customIcon,
          });

          // Store the marker using doctor's name as the key
          this.markers[doctorName] = marker;

          const colors = {
            "Approved": "#4285F4",
            "Cancelled": "#DB4437",
            "In Progress": "#F4B400",
          }

          const patientListItems = marker_data.patients.map(patient => {
            const statusColor = colors[patient.status] || "#000000"; // Default to black if status color is not defined
            return `<li style="display: flex; gap: 4px; align-items: center;"><span style="color: ${statusColor};">■</span> ${patient.patient_name}</li>`;
          }).join('');

          const contentString = `
          <div style="color: white;">
            <ul style="list-style: none; padding: 0; margin: 0;">
              <li><p style="font-size: 15px;">${marker_data.doctor}</p></li>
              ${patientListItems || '<li>No current booking</li>'}
            </ul>
          </div>
          `;

          // Creating and using the custom overlay
          const customOverlay = new CustomOverlay(marker.getPosition(), contentString, this.map);

          // Show the div on hover
          marker.addListener('mouseover', () => {
            customOverlay.div.style.display = 'block';
          });

          // Hide the div when not hovering
          marker.addListener('mouseout', () => {
            customOverlay.div.style.display = 'none';
          });
        }
      });
    } catch (error) {
      console.error("Error updating markers:", error);
    }
  }
}
